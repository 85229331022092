import React, { useEffect, useState, useMemo, useRef, useContext, useCallback } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Subscription } from 'observable-fns';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	TreeList,
	Column,
	Scrolling,
	Selection,
	SearchPanel,
	StateStoring,
	LoadPanel,
	ColumnChooser,
} from 'devextreme-react/tree-list';

import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Button } from 'semantic-ui-react';

import { OverlayTrigger } from 'react-bootstrap';

import {
	TradingPositionState,
	TradingPosition,
	TradingAccount,
	PriceQuote,
} from '../../../../../gateways/RfpGateway/rfp.types';

import {
	formatNumberWithCommas,
	revertToTraditionalNumberFormat,
} from '../../Watchlist/Instrument/formattedQuoteNumber';

import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../../contexts/RfpGatewayContext';

import { default as usePromiseFactory } from '../../../../../utils/hooks/usePromiseFactory';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import { default as Nullable, Optional } from '../../../../../utils/functions/Nullable';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import {
	getInstrumentDetails,
	convertAmountToLot,
	getMarketItemPipSize,
	calcPercentChange,
	calcPercentChangeAtParams,
	getCurrentPrice,
} from '../../../../../utils/functions/calculations';

import Tooltip from '../../../../components/Tooltip/Tooltip';
import InstrumentContext from '../../../../../contexts/InstrumentContext';
import {
	AppComponentType,
	QUANTITY_TYPE,
	TicketLayout,
	TradingPositionLimitType,
} from '../../../../../utils/functions/enums';
import StringUtils from '../../../../../utils/functions/StringUtils';
import { getGeneralFormatDate } from '../../../../../utils/functions/subscriptionUtils';

import orderStore from '../../../../../store/OrderStore/orderStore';
import { DX_COLUMN_CHOOSER_SIZE } from '../../../../../setup/config';

import WtrPopup from '../../../../components/WtrPopup/WtrPopup';

import useOrderTicketAccess from '../../../../../utils/hooks/useOrderTicketAccess';

import WtrTooltip from '../../../../../shared/WtrTooltip/WtrTooltip';
import MoneyFormatter from '../../../../components/MoneyFormatter';

import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import pipStore from '../../../../../store/PipsStore/pipStore';

import styles from './PositionGrid.module.scss';
import { CALENDAR_MONTHS_SHORT_EN } from '../../../../../utils/functions/constants';

type GridRecord = {
	position: TradingPosition | undefined;
	id: string | number;
	headID: string | number;
	posId?: Optional<number>;
	aId?: Optional<number>;
	state: TradingPositionState;
	Instrument: string;
	Side: string;
	SideTranslated: any;
	Amount: string;
	Lots?: string;
	'On Date'?: Optional<string | number>;
	'On Date Translated'?: Optional<string>;
	'Open @'?: Optional<string | number>;
	'% Change'?: Optional<string | number>;
	Current?: Optional<any>;
	Previous?: Optional<number>;
	TakeProfit?: Optional<string | number>;
	StopLoss?: Optional<string | number>;
	NetPL?: Optional<string | number | any>;
	GrossPL?: Optional<string | number | any>;
	'Pips Change'?: Optional<string | number>;
	Exposure?: Optional<string | number>;
	'Margin Usage'?: Optional<string | number>;
	oP?: Optional<string | number>;
	type: string;
	qty?: Optional<string | number>;
	code: string;
	qCcy?: Optional<string>;
	prc?: Optional<string | number>;
	comm?: Optional<string | number>;
	swap?: Optional<string | number>;
	sl?: Optional<string | number>;
	tp?: Optional<string | number>;
	isGroupRow: boolean;
	oldestDate?: Optional<number>;
	newestDate?: Optional<number>;
	Time?: Optional<string>;
	decPrec?: Optional<number>;
	dividend?: Optional<string | number>;
	comment?: Optional<string | number>;
};

const orderIconsSize = '20px';

const PositionGrid = React.memo(
	({ searchTerm, setSearchTerm }: { searchTerm: string; setSearchTerm: (t: string) => void }) => {
		const appContext = useContext(AppContext);
		const instrumentContext = useContext(InstrumentContext);
		const dashboardContext = useContext(DashboardContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);
		const promiseFactory = usePromiseFactory();
		const forceRerender = useForceRerender();
		const { t } = useTranslation();
		const tt = useShortTranslation('en:');
		const orderTicketAccess = useOrderTicketAccess();

		const fillStoreFromPosition = orderStore.use.fillStoreFromPosition();
		const stopLossPips = pipStore.use.stopLoss();
		const takeProfitPips = pipStore.use.takeProfit();
		const setTradeProps = orderStore.use.setTradeProps();
		const isArabic = appContext.isArabic;
		const languageSettings = appContext.languageSettings;
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		const tradingPositions = dashboardContext.getTradingPositions();
		const selectedPosition = dashboardContext.selectedPosition;
		const marketItems = dashboardContext.marketItems;
		const selectedTradingAccount = useSelectedTradingAccount()?.id;
		const tradingAccounts = dashboardContext.tradingAccount;
		const detailedInformation = dashboardContext.detailedInformation;
		const ticketLayout = dashboardContext.ticketLayout;
		const maximizeGrid = dashboardContext.maximizeGrid;
		const quantityType = dashboardContext.quantityType;
		const selectedInstrument = dashboardContext.selectedInstrument;
		const showOrderTicket = dashboardContext.showOrderTicket;
		const activeTradingAccount = useSelectedTradingAccount();

		useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

		useObservable(dashboardContext.getPropertyChangeStream('tradingPositionsLength'), () => forceRerender());

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'tradingPositions',
				'presentComponentType',
				'tradingAccount',
				'selectedPosition',
				'selectedSymbol',
				'marketItems',
				'detailedInformation',
				'ticketLayout',
				'tradeInformation',
				'newOrderModalToggle',
				'maximizeGrid',
				'quantityType',
				'selectedInstrument',
				'showOrderTicket',
				'accountValues',
				'watchlist'
			),
			async () => {
				await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
				forceRerender();
			}
		);

		const subscriptionRef = useRef<Optional<Subscription<PriceQuote>>>();
		const treeList = useRef<TreeList>(null);
		const feedId = `${window.name}-positions-openPositions`;

		const [headerSelect, setHeaderSelect] = useState('');
		const [emptyState, setEmptyState] = useState(false);
		const [highlightChanged, setHighlightChanged] = useState(false);
		const [highlightPosition, setHighlightPosition] = useState('');
		const [highlightedInstrument, setHighlightedInstrument] = useState('');
		const [gridLength, setGridLength] = useState(0);
		const [columnsUpdated, setColumnsUpdated] = useState(false);

		useEffect(() => {
			return () => {
				if (subscriptionRef.current) {
					subscriptionRef.current.unsubscribe();
					subscriptionRef.current = null;
				}
				Nullable.of(rfpGatewayContext).run((rfpGateway) => {
					rfpGateway.deletePriceFeed(feedId).deleteHistoryFeed(feedId);
				});
			};
		}, []);

		useEffect(() => {
			if (selectedInstrument && selectedInstrument.code && selectedInstrument.code !== highlightedInstrument) {
				setHighlightedInstrument(selectedInstrument.code);
				let positionToHighlight = selectedInstrument.code;
				if (selectedPosition && selectedPosition.length === 1 && selectedPosition[0].code === selectedInstrument.code) {
					const recordToHighlight = gridModel?.records?.find((record) => selectedPosition[0].posId === record.posId);
					positionToHighlight = recordToHighlight?.id.toString() ?? selectedInstrument.code;
				}
				setHighlightPosition(positionToHighlight);
				setHighlightChanged(true);
			}
		}, [selectedInstrument, selectedPosition, activeTradingAccount]);

		useEffect(() => {
			if (dashboardContext.presentComponentType !== AppComponentType.Watchlist) {
				handleHideColumnChooser();
			}
		}, [dashboardContext.presentComponentType]);

		const addCommas = (current: number | string, decPrec?: number | null, isPipsChange?: boolean | null) => {
			decPrec = decPrec ? decPrec : current.toString().split('.')[1]?.length ?? 0;

			if (isPipsChange) decPrec = 2;

			return formatNumberWithCommas(current, decPrec, languageSettings);
		};

		const positionGridColumnsData = () => {
			const localData = localStorage.getItem('positionGridColumns');
			if (localData && localData !== 'undefined') {
				return localData;
			}
			return '[]';
		};

		//create map of positions indexed by symbol
		const openPositionsBySymbol = useMemo(
			() =>
				tradingPositions.reduce((map, position) => {
					if (position.state === TradingPositionState.open && position.aId === selectedTradingAccount) {
						if (!map.has(position.code)) {
							map.set(position.code, []);
						}
						map.get(position.code)!.push(position);
					}
					return map;
				}, new Map<string, TradingPosition[]>()),
			[tradingPositions, selectedTradingAccount]
		);

		const gridModel = useMemo(() => {
			//get trading account
			const tradingAccount = tradingAccounts.find((value) => value.id === selectedTradingAccount) as TradingAccount;

			//unsubscribe existing subscription
			if (subscriptionRef.current) {
				subscriptionRef.current.unsubscribe();
				subscriptionRef.current = null;
			}

			//create price feed
			const priceFeed = Nullable.of(rfpGatewayContext)
				.map((rfpGateway) => rfpGateway.createPriceFeed(feedId))
				.get();

			//subscribe to price updates
			Nullable.of(priceFeed).run((priceFeed) => {
				priceFeed
					.unsubscribe((subscription) => !openPositionsBySymbol.has(subscription.code))
					.then(() => {
						const subscriptions = new Array<{ feedId: string; code: string }>();
						openPositionsBySymbol.forEach((positions) => {
							positions.forEach((pos) => {
								subscriptions.push({ feedId: pos.f, code: pos.code });
								pos.additionalSubscriptionPair.forEach((pair) => {
									subscriptions.push({ feedId: pos.f, code: pair });
								});
							});
						});

						if (subscriptions.length > 0) {
							priceFeed.subscribe(subscriptions);
						}
					});
			});

			//populate grid records with "group" rows
			const gridRecords: GridRecord[] = [];
			openPositionsBySymbol.forEach((positions, symbol) => {
				let sellAmount = 0;
				let buyAmount = 0;
				let quantity = 0;
				let summaryPrice = 0;
				let openDate: any = [];
				let oldestDate = Infinity;
				let newestDate = 0;
				positions.forEach((position) => {
					let openPrice = 0;
					if (position.side === 'BUY') {
						buyAmount += position.qty || 0;
					} else {
						sellAmount += position.qty || 0;
					}
					openPrice = (position.oP || 0) * (position.qty || 0);
					summaryPrice += openPrice;
					quantity += position.qty || 0;
					openDate.push(position.oT);
					if (moment(position.oT).valueOf() > newestDate) newestDate = moment(position.oT).valueOf();
					if (moment(position.oT).valueOf() < oldestDate) oldestDate = moment(position.oT).valueOf();
				});

				if (positions.length > 1 && detailedInformation) {
					const marketItem = rfpGatewayContext?.getMarketItem(symbol);
					const decPrec = marketItem?.decPrec;
					const currentPrice = getCurrentPrice(positions[0].f, symbol);
					const calAmount = buyAmount > 0 ? buyAmount - sellAmount : sellAmount;
					const squareAmount = calAmount === 0;
					const side = squareAmount ? 'SQUARE' : buyAmount > sellAmount ? 'BUY' : 'SELL';
					const current = side === 'SELL' ? currentPrice?.a.toFixed(decPrec) : currentPrice?.b.toFixed(decPrec);
					const open = (summaryPrice / quantity).toFixed(decPrec);
					const amount = calAmount < 0 ? calAmount * -1 : calAmount;
					let selectedInstrumentDetails = getInstrumentDetails(
						detailedInformation,
						activeTradingAccount ? [activeTradingAccount] : [],
						marketItem?.code as string
					);
					const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
					const lots = convertAmountToLot(amount, instrumentRndLot);
					const codeOrTicker =
						marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
							? marketItem.exchangeTicker
							: symbol;

					let percentChanges = calcPercentChangeAtParams(parseFloat(open), parseFloat(current!), side === 'BUY');
					const sumValue = {
						amount,
						lots,
						side,
						open,
						change: percentChanges,
						current: parseFloat(current!).toFixed(marketItem?.decPrec ?? 0),
					};

					gridRecords.push({
						position: undefined,
						id: symbol,
						headID: -1,
						state: TradingPositionState.open,
						Instrument: `${codeOrTicker} (${positions.length})`,
						Side: sumValue.side.toUpperCase(),
						SideTranslated:
							sumValue.side.toUpperCase() === 'SQUARE' ? t('wtr:SQUARE') : tt(`${sumValue.side.toUpperCase()}`),

						Amount: addCommas(sumValue.amount ? Math.round(sumValue.amount * 100) / 100 : 0),
						Lots: addCommas(sumValue.lots ? Math.round(sumValue.lots * 100) / 100 : 0),
						'On Date': '',
						'On Date Translated': '',
						'Open @': addCommas(+parseFloat(sumValue.open).toFixed(decPrec).toString(), decPrec),
						'% Change': sumValue.change,
						Current: +parseFloat(sumValue.current).toFixed(decPrec),
						TakeProfit: 'Head',
						StopLoss: 'Head',
						NetPL: '',
						GrossPL: '',
						'Pips Change': ((+sumValue.current! - +sumValue.open!) / getMarketItemPipSize(marketItem)).toFixed(2),
						Exposure: '',
						'Margin Usage': '',
						oP: sumValue.open,
						type: '',
						qty: '',
						code: symbol,
						qCcy: '',
						prc: '',
						comm: '',
						swap: '',
						isGroupRow: true,
						Previous: 0,
						oldestDate: oldestDate,
						newestDate: newestDate,
						decPrec: decPrec,
						dividend: '',
						comment: '',
					});
				}
			});

			//populate grid records with individual positions
			tradingPositions.forEach((position, index) => {
				const marketItem = position.marketItem;
				const positionLength = Nullable.of(openPositionsBySymbol.get(position.code))
					.map((value) => value.length)
					.orElse(0);

				if (
					position.state === TradingPositionState.open &&
					position.aId === selectedTradingAccount &&
					detailedInformation
				) {
					const decPrec = marketItem?.decPrec ?? 2;
					const currentPrice = position.currentPrice ?? NaN;
					const netPL = (position.netProfit ?? NaN).toFixed(2);
					const grossPL = (position.grossProfit ?? NaN).toFixed(2);
					const currentChange = calcPercentChange(position);

					let selectedInstrumentDetails = getInstrumentDetails(
						detailedInformation,
						activeTradingAccount ? [activeTradingAccount] : [],
						marketItem?.code as string
					);
					const instrumentRndLot = (selectedInstrumentDetails && selectedInstrumentDetails.rndLot) || 0;
					let lots: string | number = convertAmountToLot(position.qty || 0, instrumentRndLot);
					const codeOrTicker =
						marketItem && marketItem.exchangeTicker && marketItem.exchangeTicker !== ''
							? marketItem.exchangeTicker
							: position.code;

					const onDateTranslated = appContext.isJapanAccount
						? getGeneralFormatDate(position.oT, false, true)
						: moment(position.oT).format('D/MMM/YYYY').toString().toUpperCase();

					gridRecords.push({
						position: position,
						id: positionLength !== 1 ? index : position.code,
						headID: positionLength !== 1 ? position.code : -1,
						posId: position.posId,
						aId: position.aId,
						state: position.state,
						Instrument: codeOrTicker,
						Side: position.side.toUpperCase(),
						SideTranslated: tt(`${position.side.toUpperCase()}`),
						Amount: addCommas(position.qty ? Math.round(position.qty * 100) / 100 : 0),
						Lots: addCommas(lots ? Math.round(lots * 100) / 100 : 0),
						'On Date': position.oT,
						'On Date Translated': onDateTranslated,
						'Open @': addCommas((+position.oP!).toFixed(decPrec).toString(), decPrec),
						'% Change': currentChange,
						Current: currentPrice,
						TakeProfit: parseFloat((position.tp as number)?.toString()).toFixed(decPrec),
						StopLoss: parseFloat((position.sl as number)?.toString()).toFixed(decPrec),
						NetPL: netPL,
						GrossPL: grossPL,
						'Pips Change': (position.pips ?? NaN).toFixed(2),
						Exposure: '',
						'Margin Usage': '',
						oP: position.oP?.toFixed(decPrec),
						type: position.type || '',
						qty: position.qty,
						code: position.code,
						qCcy: position.qCcy,
						prc: position.prc,
						comm: position.comm,
						swap: position.swap,
						sl: position.sl,
						tp: position.tp,
						isGroupRow: false,
						Previous: 0,
						Time: moment(position.oT).format('HH:mm:ss').toLocaleUpperCase(),
						decPrec: decPrec,
						dividend: position.dividend,
						comment: position.comment,
					});
				}
			});

			//update P/L values on "group" records
			gridRecords
				.filter((record) => record.isGroupRow)
				.forEach((record) => {
					record.NetPL = gridRecords
						.filter((value) => value.code === record.code && !value.isGroupRow)
						.reduce((totalPl, gridRecord) => {
							return +totalPl + (isNaN((gridRecord.NetPL || Number.NaN) as number) ? 0 : +gridRecord?.NetPL!);
						}, 0);
					record.GrossPL = gridRecords
						.filter((value) => value.code === record.code && !value.isGroupRow)
						.reduce((totalPl, gridRecord) => {
							return +totalPl + (isNaN((gridRecord.GrossPL || Number.NaN) as number) ? 0 : +gridRecord?.GrossPL!);
						}, 0);
				});

			setGridLength(gridRecords.length);

			setEmptyState(gridRecords.length === 0);

			//create grid model
			const tradeStore = new ArrayStore({ key: 'id', data: gridRecords });
			const model = {
				records: gridRecords,
				tradeStore: tradeStore,
				dataSource: new DataSource({ store: tradeStore, reshapeOnPush: true }),
				tradingAccount: tradingAccount,
				months: CALENDAR_MONTHS_SHORT_EN,
			};

			//subscribe to price updates
			Nullable.of(priceFeed).run((priceFeed) => {
				subscriptionRef.current = priceFeed.stream.subscribe((priceQuote) => {
					openPositionsBySymbol.forEach((positions) => {
						positions.forEach((position) => {
							const marketItem = position.marketItem;
							if (marketItem && marketItem.feedId === priceQuote.f) {
								if (marketItem.code === priceQuote.c || position.additionalSubscriptionPair.has(priceQuote.c)) {
									const decPrec = marketItem.decPrec;
									const updates: any[] = [];
									let aggregateNetPL: number = 0;
									let aggregateGrossPL: number = 0;
									let aggregatePlPips: any = 0;
									//update current price and P/L on individual records
									model.records
										.filter((value) => value.code === position.code && !value.isGroupRow)
										.forEach((record) => {
											//let updatedRecord = { ...record }; // creating copy of variable
											record.Previous = record.Current; // update the name property, assign a new value

											if (record.code === priceQuote.c) {
												record.Current =
													record.Side === 'SELL' ? priceQuote.a.toFixed(decPrec) : priceQuote.b.toFixed(decPrec);
											}
											record['Pips Change'] = record.position?.pips ?? NaN;
											record.NetPL = record.position?.netProfit ?? NaN;
											record.GrossPL = record.position?.grossProfit ?? NaN;
											record['% Change'] = record.position ? calcPercentChange(record.position) : NaN;
											aggregateNetPL += record.NetPL;
											aggregateGrossPL += record.GrossPL;
											aggregatePlPips = aggregatePlPips + record['Pips Change'];
											updates.push({
												type: 'update',
												key: record.id,
												data: {
													Current: record.Current,
													Previous: record.Previous,
													NetPL: record.NetPL,
													GrossPL: record.GrossPL,
													'% Change': record['% Change'],
													'Pips Change': record['Pips Change'].toFixed(2),
												},
											});
										});

									//update current price and P/L on "group" records
									Nullable.of(model.records.find((value) => value.code === position.code && value.isGroupRow)).run(
										(record) => {
											//let updatedRecord = { ...record }; //Object.assign({}, record);  // creating copy of variable
											record.Previous = record.Current; // update the name property, assign a new value
											if (record.code === priceQuote.c) {
												record.Current =
													record.Side === 'SELL' ? priceQuote.a.toFixed(decPrec) : priceQuote.b.toFixed(decPrec);
											}

											const currentVal = record.Current ?? '';
											const openVal = record.oP ?? '';
											const percentChange = calcPercentChangeAtParams(+openVal, +currentVal, record.Side === 'BUY');
											record.NetPL = aggregateNetPL;
											record.GrossPL = aggregateGrossPL;
											record['Pips Change'] = aggregatePlPips.toFixed(2);
											record['% Change'] = percentChange;
											updates.push({
												type: 'update',
												key: record.id,
												data: {
													Current: record.Current,
													Previous: record.Previous,
													NetPL: record.NetPL,
													GrossPL: record.GrossPL,
													'% Change': record['% Change'],
													'Pips Change': record['Pips Change'],
												},
											});
										}
									);

									//push any updates to trade store
									if (updates.length > 0) {
										model.tradeStore.push(updates);
									}
								}
							}
						});
					});
				});
			});
			return model;
		}, [tradingPositions, selectedTradingAccount, tradingAccounts, quantityType, searchTerm]);

		const showClose = (ev: any) => {
			if (ticketLayout === TicketLayout.Dock) {
				if ((ev.data && ev.data.Current > 0) || ev.rowType === 'header') {
					dashboardContext.closeAllOtherTabs();
					dashboardContext.showCloseTicket = true;
				}
			} else {
				dashboardContext.modalToggle = { closePosition: true, closeOrder: false };
			}
			if (ev.rowType === 'header') {
				const allPositions = tradingPositions.filter(
					(open: TradingPosition) => open.state === TradingPositionState.open && open.aId === activeTradingAccount?.id
				);
				dashboardContext.headerSelected = true;
				dashboardContext.selectedPosition = allPositions;
			} else {
				dashboardContext.headerSelected = false;
			}
		};

		const showCloseIcon = (ev: any) => {
			const iconStyle = isArabic
				? {
						fontSize: orderIconsSize,
						marginRight: '5px',
				  }
				: {
						fontSize: orderIconsSize,
						marginLeft: '5px',
				  };
			return (
				<OverlayTrigger
					delay={{ show: 750, hide: 0 }}
					key={'closeAllPositions'}
					placement="bottom-end"
					overlay={
						<Tooltip className="my-tooltip" id={'closeAllPositions'}>
							{t('en:TRADES_CLOSE_ALL_POSITIONS')}
						</Tooltip>
					}
				>
					<div className={styles.triggerContainer} onClick={() => showClose(ev)}>
						<FontAwesomeIcon icon={['fas', 'times-circle']} style={iconStyle} className={styles.icon} />
					</div>
				</OverlayTrigger>
			);
		};

		const showClosePositionIcon = (e: any) => {
			const iconStyle = isArabic
				? {
						fontSize: orderIconsSize,
						marginRight: '7px',
				  }
				: {
						fontSize: orderIconsSize,
						marginLeft: '7px',
				  };
			return (
				<OverlayTrigger
					delay={{ show: 750, hide: 0 }}
					key={`close${e.data.code}`}
					placement={isArabic ? 'right' : 'left'}
					overlay={
						<Tooltip className="my-tooltip" id={`close${e.data.code}`}>
							{t('en:TRADES_CLOSE_POSITION')}
						</Tooltip>
					}
				>
					<div className={styles.triggerContainer} onClick={() => showClose(e)}>
						<FontAwesomeIcon icon={['fas', 'times-circle']} style={iconStyle} className={styles.icon} />
					</div>
				</OverlayTrigger>
			);
		};

		const showSideTypeAndIcon = (e: any) => {
			if (!e.displayValue) return null;
			return (
				<>
					{e.row.data.Side === 'SQUARE' ? (
						<div className={styles.sideTooltipWrapper}>
							<WtrTooltip content={t('en:POSITION_SQUARE_TOOLTIP')} position="right center" wide="very">
								<div className={styles.tooltipWrapper}>
									<div className={isArabic ? styles.sideTooltipSquareArabic : styles.sideTooltipSquare}>
										{t(e.displayValue)}
									</div>
									<div className="gridMenu" id="gridMenu">
										<FontAwesomeIcon className={styles.sideTooltipSquareIcon} icon={['fas', 'circle-info']} />
									</div>
								</div>
							</WtrTooltip>
						</div>
					) : (
						<div>{t(e.displayValue)}</div>
					)}
				</>
			);
		};

		const renderOnDate = (data: any) => {
			const record: GridRecord = data.data;
			return <div style={{ direction: 'ltr' }}>{record['On Date Translated']}</div>;
		};

		const calculateCurrent = (data: any) => {
			const record: GridRecord = data.data;
			const colorValue = record.Current && record.Previous && record.Current >= record.Previous ? 'green' : 'red';
			return (
				<div className={colorValue === 'green' ? styles.greenCurrent : styles.redCurrent}>
					{isNaN(record.Current) ? t('wtr:NA') : addCommas(record.Current, record?.decPrec)}
				</div>
			);
		};

		const calcNetPL = (data: any) => {
			const record: GridRecord = data.data;
			let netPLValue = isNaN((record.NetPL || Number.NaN) as number) ? 0 : (record.NetPL as number);
			return (
				<div className={netPLValue > 0 ? styles.greenCurrent : styles.redCurrent}>
					<MoneyFormatter money={Number(netPLValue)} />
				</div>
			);
		};

		const calcGrossPL = (data: any) => {
			const record: GridRecord = data.data;
			let grossPLValue = isNaN((record.GrossPL || Number.NaN) as number) ? 0 : (record.GrossPL as number);
			return (
				<div className={grossPLValue > 0 ? styles.greenCurrent : styles.redCurrent}>
					<MoneyFormatter money={grossPLValue} />
				</div>
			);
		};

		const calculateDollarValue = (data: any) => {
			const record: GridRecord = data.data;
			const field: keyof GridRecord = data.column.dataField;
			return <div>{!record.isGroupRow && <MoneyFormatter money={Number(record[field])} />}</div>;
		};

		const calculateComment = (data: any) => {
			const record: GridRecord = data.data;
			const comment = StringUtils.isNullOrEmpty(record.comment?.toString()) ? t('wtr:NA') : record.comment;
			return <div>{!record.isGroupRow && comment}</div>;
		};

		const calculatePLPips = (data: any) => {
			const record: GridRecord = data.data;
			let profitLossPipsValue = isNaN((record['Pips Change'] || Number.NaN) as number)
				? 0
				: (record['Pips Change'] as number);
			return (
				<div style={{ direction: 'ltr' }} className={profitLossPipsValue > 0 ? styles.greenCurrent : styles.redCurrent}>
					{addCommas(profitLossPipsValue, null, true)}
				</div>
			);
		};

		const getPercentChange = (data: any) => {
			const record: GridRecord = data.data;
			const percentChange = isNaN((record['% Change'] || Number.NaN) as number) ? 0 : (record['% Change'] as number);

			return (
				<div style={{ direction: 'ltr' }} className={percentChange > 0 ? styles.greenCurrent : styles.redCurrent}>
					{formatNumberWithCommas(percentChange, 2, languageSettings)}%
				</div>
			);
		};

		const handleShowColumnChooser = () => {
			if (treeList.current) {
				treeList.current.instance.showColumnChooser();
			}
		};

		const handleHideColumnChooser = () => {
			if (treeList.current) {
				treeList.current.instance.hideColumnChooser();
			}
		};

		const showCogIcon = () => {
			const iconStyle = isArabic
				? {
						fontSize: orderIconsSize,
						marginRight: '5px',
				  }
				: {
						fontSize: orderIconsSize,
						marginLeft: '5px',
				  };
			return (
				<OverlayTrigger
					delay={{ show: 750, hide: 0 }}
					key={'positionsGridSettings'}
					placement="bottom-end"
					overlay={
						<Tooltip className="my-tooltip" id={'positionsGridSettings'}>
							{t('en:SETTINGS')}
						</Tooltip>
					}
				>
					<div className={styles.triggerContainer} onClick={handleShowColumnChooser}>
						<FontAwesomeIcon icon={['fas', 'cog']} style={iconStyle} className={styles.icon} />
					</div>
				</OverlayTrigger>
			);
		};

		const hidePopup = () => {
			const popup = document.getElementsByClassName(`ui top ${isArabic ? 'left' : 'right'} popup transition visible`);
			if (popup && popup.length > 0) popup[0].className = `ui top ${isArabic ? 'left' : 'right'} popup transition`;
		};

		const openTicket = (e: any) => {
			hidePopup();
			dashboardContext.closeAllOtherTabs();

			// change the Trade context state to 'open' and fill the trade context with the selected position (needed for the TradeTicket.tsx)
			// and show limit fields only (take profit / stop loss)
			let limitsType: TradingPositionLimitType[] = [];
			if (e.column.name === 'StopLoss') {
				limitsType.push(TradingPositionLimitType.StopLoss);
			} else if (e.column.name === 'TakeProfit') {
				limitsType.push(TradingPositionLimitType.TakeProfit);
			} else if (e.data.StopLoss !== 'NaN' && e.data.TakeProfit !== 'NaN') {
				limitsType.push(TradingPositionLimitType.TakeProfit, TradingPositionLimitType.StopLoss);
			}

			fillStoreFromPosition(e.data.position, limitsType, true, { takeProfitPips, stopLossPips });

			setTradeProps({
				isPreservedValue: true,
			});

			if (ticketLayout === TicketLayout.Dock) {
				dashboardContext.showOrderTicket = true;

				// TODO: - Check it the code bellow is needed when refactor the grid
				if (e.column.name === 'StopLoss' || e.column.name === 'TakeProfit') {
					dashboardContext.gridButton = e.column.name === 'StopLoss' ? 'Stop Loss' : 'Take Profit';
				} else if (e.column.caption) {
					dashboardContext.gridButton = e.column.caption;
				} else {
					dashboardContext.gridButton = 'Menu Item';
				}
				const record: GridRecord = e.data;
				if (record) {
					const positions: any = tradingPositions.filter(
						(t) =>
							t.code === record.code &&
							t.state === TradingPositionState.open &&
							t.aId === activeTradingAccount?.id &&
							(record.isGroupRow || record.posId === t.posId)
					);

					dashboardContext.selectedPosition = positions;
					dashboardContext.selectedInstrument = e.data.position.marketItem;
				}
			} else {
				dashboardContext.newOrderModalToggle = {
					orderTicket: true,
					confirmOrder: false,
				};

				// TODO: - Check it the code bellow is needed when refactor the grid
				const record: GridRecord = e.data;
				if (record) {
					const positions = tradingPositions.filter(
						(t: TradingPosition) =>
							t.code === record.code &&
							t.state === TradingPositionState.open &&
							t.aId === activeTradingAccount?.id &&
							(record.isGroupRow || record.posId === t.posId)
					);

					dashboardContext.selectedPosition = positions;
					dashboardContext.selectedInstrument = e.data.position.marketItem;
				}

				if (e.column.name === 'StopLoss' || e.column.name === 'TakeProfit') {
					dashboardContext.gridButton = e.column.name === 'StopLoss' ? 'Stop Loss' : 'Take Profit';
				} else if (e.column.caption) {
					dashboardContext.gridButton = e.column.caption;
				} else {
					dashboardContext.gridButton = 'Menu Item';
				}
			}
		};

		const openMarketInformation = (code: string) => {
			hidePopup();
			if (detailedInformation) {
				const instrumentDetail = getInstrumentDetails(
					detailedInformation,
					activeTradingAccount ? [activeTradingAccount] : [],
					code
				);
				if (instrumentDetail) {
					let marketItem = rfpGatewayContext?.getMarketItem(instrumentDetail.code);
					if (marketItem) {
						dashboardContext.selectedInstrument = marketItem;
					}
				}

				dashboardContext.closeAllOtherTabs();
				dashboardContext.toggleAccordionMenu = '0';
				dashboardContext.showOrderInformation = true;
			}
		};

		const openPositionInformation = (code: string) => {
			hidePopup();
			if (detailedInformation) {
				const instrumentDetail = getInstrumentDetails(
					detailedInformation,
					activeTradingAccount ? [activeTradingAccount] : [],
					code
				);

				if (instrumentDetail) {
					let marketItem = rfpGatewayContext?.getMarketItem(instrumentDetail.code);
					if (marketItem) {
						dashboardContext.selectedInstrument = marketItem;
					}
				}

				dashboardContext.closeAllOtherTabs();
				dashboardContext.toggleAccordionMenu = '1';
				dashboardContext.showOrderInformation = true;
			}
		};

		const showEditIcon = (e: any) => {
			const record: GridRecord = e.data;
			const iconStyle = isArabic
				? {
						fontSize: orderIconsSize,
						marginRight: '12px',
						marginTop: 0,
				  }
				: {
						fontSize: orderIconsSize,
						marginLeft: '15px',
						marginTop: 0,
				  };
			return (
				<OverlayTrigger
					delay={{ show: 750, hide: 0 }}
					key={`more${e.data.code}`}
					placement={isArabic ? 'right' : 'left'}
					overlay={
						<Tooltip className="my-tooltip" id={`more${e.data.code}`}>
							{t('en:NAVIGATION_MORE')}
						</Tooltip>
					}
				>
					<div key={`editPopup${e.data.code}`}>
						<WtrPopup
							className={styles.Popup}
							content={
								<>
									<div className={styles.PopupContainer} onClick={() => openPositionInformation(record.code)}>
										<FontAwesomeIcon icon={['fas', 'info-circle']} size="1x" />
										<div>{tt('TRADES_POSITION_DETAILS')}</div>
									</div>
									<div
										className={styles.PopupContainer}
										onClick={() => {
											openMarketInformation(record.code);
										}}
									>
										<FontAwesomeIcon icon={['fas', 'info']} size="1x" className={styles.infoIcon} />
										<div>{t('wtr:MARKET_INFO')}</div>
									</div>
									{!e.data.isGroupRow && (
										<div className={styles.PopupContainer} onClick={() => openTicket(e)}>
											<FontAwesomeIcon icon={['fas', 'layer-plus']} size="1x" />
											<div dangerouslySetInnerHTML={{ __html: t('wtr:SET_TP_SL') }}></div>
										</div>
									)}
								</>
							}
							on={['click']}
							pinned
							position={isArabic ? 'top left' : 'top right'}
							trigger={
								<div className={styles.triggerContainer}>
									<FontAwesomeIcon icon={['fas', 'ellipsis-v']} style={iconStyle} className={styles.editTheme} />
								</div>
							}
						/>
					</div>
				</OverlayTrigger>
			);
		};

		const showProfitButton = (e: any) => {
			const record: GridRecord = e.data;
			if (record && record.TakeProfit !== 'Head') {
				return (
					<OverlayTrigger
						delay={{ show: 750, hide: 0 }}
						key={'takeProfitButton'}
						placement="top-end"
						overlay={
							<Tooltip className="my-tooltip" id={'takeProfitButton'}>
								{t('wtr:WTR_SET_TAKE_PROFIT')}
							</Tooltip>
						}
					>
						<div className={styles.plusContainer}>
							<Button className={styles.plusButton} onClick={() => openTicket(e)}>
								<div className={styles.iconContainer}>
									{isNaN((record.TakeProfit || Number.NaN) as number) ? (
										<FontAwesomeIcon icon={['fas', 'plus']} size="1x" className={styles.plusTheme} />
									) : (
										<div className={styles.profitValues}>
											{formatNumberWithCommas(record.TakeProfit!, record.decPrec ?? 0, languageSettings)}
										</div>
									)}
								</div>
							</Button>
						</div>
					</OverlayTrigger>
				);
			}
		};

		const showLossButton = (e: any) => {
			const record: GridRecord = e.data;
			if (record && record.StopLoss !== 'Head') {
				return (
					<OverlayTrigger
						delay={{ show: 750, hide: 0 }}
						key={'stopLossButton'}
						placement="top-end"
						overlay={
							<Tooltip className="my-tooltip" id={'stopLossButton'}>
								{t('wtr:WTR_SET_STOP_LOSS')}
							</Tooltip>
						}
					>
						<div className={styles.plusContainer}>
							<Button className={styles.plusButton} onClick={() => openTicket(e)}>
								<div className={styles.iconContainer}>
									{isNaN((record.StopLoss || Number.NaN) as number) ? (
										<FontAwesomeIcon icon={['fas', 'plus']} size="1x" className={styles.plusTheme} />
									) : (
										<div className={styles.profitValues}>
											{formatNumberWithCommas(record.StopLoss!, record.decPrec ?? 0, languageSettings)}
										</div>
									)}
								</div>
							</Button>
						</div>
					</OverlayTrigger>
				);
			}
		};

		const handleRowClick = (e: any) => {
			dashboardContext.showCancelTicket = false;
			if (!emptyState) {
				dashboardContext.closeAllOtherTabs();
				if (e.event.target.tagName.toLowerCase() !== 'span') {
					const record: GridRecord = e.data;
					if (record) {
						const positions: any = tradingPositions.filter(
							(t) =>
								t.code === record.code &&
								t.state === TradingPositionState.open &&
								t.aId === activeTradingAccount?.id &&
								(record.isGroupRow || record.posId === t.posId)
						);
						dashboardContext.selectedPosition = positions;
						dashboardContext.gridChartsChanged = true;

						if (marketItems && marketItems.length > 0 && record) {
							dashboardContext.selectedType = 'Grid';
							dashboardContext.isEdit = false;
							dashboardContext.showConfirmTicket = false;
							dashboardContext.selectedInstrument = rfpGatewayContext?.getMarketItem(record.code);
							setHighlightedInstrument(e.key);
							setHighlightPosition(e.data.id);
							e.component.deselectAll();
							e.component.selectRows([e.data.id]);
							e.component.navigateToRow(e.data.id);
						}
					}
				} else {
					console.log('Empty panel detected - No row click');
					return;
				}
			}
		};

		const handleStartTrading = (event: any) => {
			event.stopPropagation();
			if (orderTicketAccess()) {
				dashboardContext.ticketLayout = TicketLayout.Dock;
				dashboardContext.closeAllOtherTabs();
				dashboardContext.showOrderTicket = true;
				instrumentContext.focusInstrumentSearchInput();
				dashboardContext.startTradingNow = true;
			}
			return false;
		};

		const renderClearFilters = () => {
			return searchTerm.length > 0 ? (
				<div className={styles.emptyGridContainer}>
					<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'search']} size="5x" />
					<div className={styles.emptyStateMessage}>{t('wtr:NO_OPEN_POSITIONS_FOUND')}</div>
					<div className={styles.emptyButtonContainer}>
						<div className={styles.clearFilters} onClick={() => setSearchTerm('')}>
							{t('wtr:CLEAR_FILTERS')}
						</div>
					</div>
				</div>
			) : (
				<div className={styles.emptyGridContainer}>
					<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'chart-pie']} size="5x" />
					<div className={styles.emptyStateMessage}>
						{appContext.isJapanAccount ? t('wtr:JP_NO_OPEN_POSITIONS') : t('en:NO_OPEN_POSITIONS')}
					</div>
					<div className={styles.emptyButtonContainer}>
						<span
							className={showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
							onClick={handleStartTrading}
						>
							<FontAwesomeIcon icon={['fas', 'plus-circle']} size="1x" /> {t('en:START_TRADING')}
						</span>
					</div>
				</div>
			);
		};

		const searchMatches = useMemo(() => {
			let matches: Optional<string | number>[] = [];
			let visibleColumns: string[] = [];
			if (treeList.current) {
				treeList.current.instance.refresh();
				visibleColumns =
					treeList.current?.instance
						.getVisibleColumns()
						.filter((column) => column.dataField)
						.map((column) => column.dataField as string) ?? [];
			}
			if (visibleColumns.length === 0) {
				visibleColumns = JSON.parse(positionGridColumnsData())
					.filter((column: any) => column.visible)
					.map((column: any) => column.dataField);
			}
			gridModel.records.forEach((record) => {
				visibleColumns.forEach((columnName: string) => {
					matches.push([(record as any)[columnName]][0]);
				});
			});
			return matches;
		}, [gridModel, columnsUpdated]);

		useEffect(() => {
			const matches = searchMatches
				.map((item) => item?.toString().toUpperCase())
				.filter((item: string | undefined) => item);
			if (
				matches.length !== 0 &&
				!matches.find((match: string | undefined) => match?.includes(searchTerm.toUpperCase()))
			) {
				setEmptyState(true);
			} else if (gridLength !== 0) {
				setEmptyState(false);
			}
		}, [searchMatches, searchTerm, gridLength]);

		//Used to know when to display the empty state
		const onOptionChanged = (e: any) => {
			if (e.fullName === 'onRowClick' && searchTerm.length === 0 && highlightChanged) {
				const selectedRows = e.component.getSelectedRowKeys();
				if (selectedRows[0]?.toString() !== highlightPosition) {
					e.component.deselectAll();
					e.component.selectRows([highlightPosition]);
					// e.component.navigateToRow(highlightPosition)
					e.component.option('focusedRowKey', highlightPosition);
					setHighlightChanged(false);
				}
			} else if (e.fullName === 'dataSource') {
				e.component.hideColumnChooser();
				setHighlightChanged(true);
			}
			//When column visibilty changes, they become searchable or unsearchable. Columns updated triggers updated searchability
			else if (e.fullName.includes('.visible') && !e.fullName.includes('.visibleIndex')) {
				setColumnsUpdated(!columnsUpdated);
			}
		};

		const highlightCell = (data: any) => {
			const cssClass = cn(
				headerSelect === data.column.caption.replace(/\s/g, '') ? styles.headerCell : '',
				data.column.caption === tt('INSTRUMENT') ? styles.headerCellInstrument : ''
			);

			return (
				<div className={cssClass} tabIndex={0}>
					{data.column.caption}
				</div>
			);
		};

		const changeHighlight = (data: any) => {
			if (data.rowType === 'header') {
				setHeaderSelect(data.column.dataField?.replace(/\s/g, ''));
			}
		};

		const handleSortPLPips = useCallback((rowValue: any) => {
			return +rowValue['Pips Change'];
		}, []);

		const handleSortNetPL = useCallback((rowValue: any) => {
			return +rowValue.NetPL;
		}, []);

		const handleSortGrossPL = useCallback((rowValue: any) => {
			return +rowValue.GrossPL;
		}, []);

		const rowsExpanded = useMemo(() => {
			const localItem = localStorage.getItem('positionGridExpand');
			if (localItem) return JSON.parse(localStorage.getItem('positionGridExpand') as string);
			return [];
		}, []);

		const customSave = useCallback(
			(e) => {
				if (!emptyState) {
					localStorage.setItem(
						'positionGridColumns',
						JSON.stringify(
							(e.columns as any[])?.map((c) => ({
								dataField: c.dataField,
								dataType: c.dataType,
								name: c.name,
								visible: c.visible,
								visibleIndex: c.visibleIndex,
								width: c.width,
								sortOrder: c.sortOrder,
								sortIndex: c.sortIndex,
							}))
						)
					);
					if (e.expandedRowKeys) {
						localStorage.setItem('positionGridExpand', JSON.stringify(e.expandedRowKeys));
					}
					const dateColumn = (e.columns || []).find(
						(column: { dataField: string }) => column.dataField === 'On Date Translated'
					);
					if (dateColumn) {
						localStorage.setItem('dateDirection', JSON.stringify(dateColumn.sortOrder));
					}
				}
			},
			[emptyState]
		);

		const sortOpenDates = useCallback((rowValue: any) => {
			const direction = JSON.parse(localStorage.getItem('dateDirection') ?? 'desc');
			return rowValue.isGroupRow
				? moment(direction === 'asc' ? rowValue.oldestDate : rowValue.newestDate).valueOf()
				: moment(rowValue['On Date']).valueOf();
		}, []);

		const sortOpenAt = useCallback((rowValue: any) => {
			return Number(revertToTraditionalNumberFormat(rowValue['Open @'], languageSettings));
		}, []);

		const sortAmountLots = useCallback(
			(rowValue: any) => {
				const value = quantityType === QUANTITY_TYPE.LOTS ? rowValue.Lots : rowValue.Amount;
				return Number(revertToTraditionalNumberFormat(value, languageSettings));
			},
			[quantityType]
		);

		const columnsMap: { [key: string]: { [key: string]: any } } = useMemo(
			() =>
				JSON.parse(positionGridColumnsData()).reduce((res: any, current: any) => {
					res[current.dataField] = current;
					return res;
				}, {}),
			[]
		);
		const hasColumns = useMemo(() => columnsMap && Object.keys(columnsMap).length > 0, [columnsMap]);

		const getAttribute = useCallback(
			(attribute: string, dataField: string) => {
				if (emptyState && attribute === 'visible') return false;
				if (!hasColumns) return undefined;
				return columnsMap[dataField]?.[attribute];
			},
			[emptyState]
		);

		const quantityTypeString = useMemo(() => (quantityType === QUANTITY_TYPE.LOTS ? 'Lots' : 'Amount'), [quantityType]);

		if (emptyState) {
			return renderClearFilters();
		}
		return (
			<div className={styles.grid}>
				<TreeList
					dataSource={gridModel.dataSource}
					allowColumnResizing={true}
					columnResizingMode="nextColumn"
					showRowLines={true}
					showColumnLines={false}
					showBorders={true}
					rootValue={-1}
					keyExpr="id"
					parentIdExpr="headID"
					className={!maximizeGrid ? styles.treeListLayout : styles.maximizeTreeListLayout}
					ref={treeList}
					onOptionChanged={onOptionChanged}
					onRowClick={handleRowClick}
					repaintChangesOnly={true}
					onCellClick={changeHighlight}
					hoverStateEnabled={!emptyState}
					defaultExpandedRowKeys={rowsExpanded}
					autoNavigateToFocusedRow={true}
					rtlEnabled={isArabic}
				>
					<ColumnChooser
						title={t('wtr:COLUMN_CHOOSER')}
						emptyPanelText={t('wtr:DRAG_COLUMN')}
						width={DX_COLUMN_CHOOSER_SIZE.width}
						height={DX_COLUMN_CHOOSER_SIZE.height}
					/>
					<LoadPanel enabled={false} />
					<SearchPanel
						visible={false}
						placeholder={t('wtr:SEARCH_FOR_POSITION')}
						text={searchTerm}
						width={200}
						searchVisibleColumnsOnly={true}
					/>
					<Selection mode={emptyState ? 'none' : 'single'} />
					<StateStoring enabled={true} type="custom" customSave={customSave} savingTimeout={0} />
					<Column
						dataField="Instrument"
						caption={tt('INSTRUMENT')}
						allowHiding={false}
						fixedPosition={isArabic ? 'right' : 'left'}
						sortIndex={getAttribute('sortIndex', 'Instrument')}
						sortOrder={getAttribute('sortOrder', 'Instrument') ?? 'asc'}
						headerCellRender={highlightCell}
						visible={!emptyState}
						visibleIndex={0}
						allowSearch={!emptyState}
						width={getAttribute('width', 'Instrument') ?? 190}
						minWidth="120"
						alignment={isArabic ? 'right' : ''}
						cellRender={(data: any) => <>{data.data['Instrument']}&lrm;</>} // display groupеd brackets properly
						fixed={true}
					/>
					<Column
						allowReordering={true}
						caption={tt('SIDE')}
						sortIndex={getAttribute('sortIndex', 'SideTranslated')}
						sortOrder={getAttribute('sortOrder', 'SideTranslated') ?? 'desc'}
						dataField="SideTranslated"
						headerCellRender={highlightCell}
						cellRender={showSideTypeAndIcon}
						visible={getAttribute('visible', 'SideTranslated') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'SideTranslated') ?? 1}
						allowSearch={!emptyState}
						width={getAttribute('width', 'SideTranslated')}
						minWidth="100"
						alignment={isArabic ? 'right' : ''}
					/>
					<Column
						allowReordering={true}
						caption={tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
						calculateSortValue={sortAmountLots}
						sortIndex={getAttribute('sortIndex', quantityTypeString)}
						sortOrder={getAttribute('sortOrder', quantityTypeString) ?? 'desc'}
						dataField={quantityTypeString}
						alignment="right"
						headerCellRender={highlightCell}
						visible={getAttribute('visible', quantityTypeString) ?? true}
						visibleIndex={getAttribute('visibleIndex', quantityTypeString) ?? 2}
						allowSearch={!emptyState}
						width={getAttribute('width', quantityTypeString)}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={t('wtr:ON_DATE')}
						calculateSortValue={sortOpenDates}
						sortIndex={getAttribute('sortIndex', 'On Date Translated')}
						sortOrder={getAttribute('sortOrder', 'On Date Translated') ?? 'desc'}
						dataField="On Date Translated"
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'On Date Translated') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'On Date Translated') ?? 3}
						allowSearch={!emptyState}
						width={getAttribute('width', 'On Date Translated')}
						alignment={isArabic ? 'right' : 'left'}
						cellRender={renderOnDate}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={tt('OPEN_AT')}
						calculateSortValue={sortOpenAt}
						sortIndex={getAttribute('sortIndex', 'Open @')}
						sortOrder={getAttribute('sortOrder', 'Open @') ?? 'desc'}
						dataField="Open @"
						alignment="right"
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'Open @') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'Open @') ?? 4}
						allowSearch={!emptyState}
						width={getAttribute('width', 'Open @')}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={t('wtr:PER_CHANGE')}
						sortIndex={getAttribute('sortIndex', '% Change')}
						sortOrder={getAttribute('sortOrder', '% Change') ?? 'desc'}
						dataField="% Change"
						alignment="right"
						cellRender={getPercentChange}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', '% Change') ?? true}
						visibleIndex={getAttribute('visibleIndex', '% Change') ?? 5}
						allowSearch={!emptyState}
						width={getAttribute('width', '% Change')}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={tt('CURRENT')}
						sortIndex={getAttribute('sortIndex', 'Current')}
						dataType="number"
						sortOrder={getAttribute('sortOrder', 'Current') ?? 'desc'}
						dataField="Current"
						alignment="right"
						cellRender={calculateCurrent}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'Current') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'Current') ?? 6}
						allowSearch={!emptyState}
						width={getAttribute('width', 'Current')}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={tt('TAKE_PROFIT')}
						allowSorting={false}
						dataField="TakeProfit"
						cellRender={showProfitButton}
						allowFiltering={false}
						visible={getAttribute('visible', 'TakeProfit') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'TakeProfit') ?? 7}
						allowSearch={!emptyState}
						width={getAttribute('width', 'TakeProfit')}
						minWidth="90"
						cssClass={styles.tpslCell}
					/>
					<Column
						allowReordering={true}
						caption={tt('STOP_LOSS')}
						allowSorting={false}
						dataField="StopLoss"
						cellRender={showLossButton}
						allowFiltering={false}
						visible={getAttribute('visible', 'StopLoss') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'StopLoss') ?? 8}
						allowSearch={!emptyState}
						minWidth="90"
						cssClass={styles.tpslCell}
					/>
					<Column
						allowReordering={true}
						caption={tt('NET_PL')}
						dataField="NetPL"
						sortIndex={getAttribute('sortIndex', 'NetPL')}
						sortOrder={getAttribute('sortOrder', 'NetPL') ?? 'desc'}
						calculateSortValue={handleSortNetPL}
						alignment="right"
						cellRender={calcNetPL}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'NetPL') ?? true}
						visibleIndex={getAttribute('visibleIndex', 'NetPL') ?? 9}
						allowSearch={!emptyState}
						width={getAttribute('width', 'NetPL')}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={tt('GROSS_PL')}
						dataField="GrossPL"
						sortIndex={getAttribute('sortIndex', 'GrossPL')}
						sortOrder={getAttribute('sortOrder', 'GrossPL') ?? 'desc'}
						calculateSortValue={handleSortGrossPL}
						alignment="right"
						cellRender={calcGrossPL}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'GrossPL') ?? false}
						visibleIndex={getAttribute('visibleIndex', 'GrossPL') ?? 9}
						allowSearch={!emptyState}
						width={getAttribute('width', 'GrossPL')}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={t(`wtr:WTR_PL_IN_${isSpreadBettingAccount ? 'POINTS' : 'PIPS'}`)}
						sortIndex={getAttribute('sortIndex', 'Pips Change')}
						sortOrder={getAttribute('sortOrder', 'Pips Change') ?? 'desc'}
						dataField="Pips Change"
						calculateSortValue={handleSortPLPips}
						alignment="right"
						cellRender={calculatePLPips}
						headerCellRender={highlightCell}
						visible={getAttribute('visible', 'Pips Change') ?? false}
						visibleIndex={getAttribute('visibleIndex', 'Pips Change') ?? 10}
						allowSearch={!emptyState}
						width={getAttribute('width', 'Pips Change')}
						minWidth="72"
					/>
					<Column
						allowReordering={true}
						caption={tt('OPEN_TIME')}
						sortIndex={getAttribute('sortIndex', 'Time')}
						sortOrder={getAttribute('sortOrder', 'Time') ?? 'desc'}
						dataField="Time"
						dataType="time"
						alignment="right"
						visible={getAttribute('visible', 'Time') ?? false}
						visibleIndex={getAttribute('visibleIndex', 'Time') ?? 11}
						allowSearch={!emptyState}
						width={getAttribute('width', 'Time')}
						minWidth="72"
						allowSorting={false}
					/>
					<Column
						allowReordering={true}
						caption={tt('SWAP')}
						sortIndex={getAttribute('sortIndex', 'swap')}
						sortOrder={getAttribute('sortOrder', 'swap') ?? 'desc'}
						dataField="swap"
						alignment="right"
						visible={getAttribute('visible', 'swap') ?? false}
						visibleIndex={getAttribute('visibleIndex', 'swap') ?? 12}
						allowSearch={!emptyState}
						width={getAttribute('width', 'swap')}
						minWidth="72"
						cellRender={calculateDollarValue}
					/>
					{!appContext.isJapanAccount && (
						<Column
							allowReordering={true}
							caption={tt('DIVIDEND')}
							sortIndex={getAttribute('sortIndex', 'dividend')}
							sortOrder={getAttribute('sortOrder', 'dividend') ?? 'desc'}
							dataField="dividend"
							alignment="right"
							visible={getAttribute('visible', 'dividend') ?? false}
							visibleIndex={getAttribute('visibleIndex', 'dividend') ?? 13}
							allowSearch={!emptyState}
							width={getAttribute('width', 'dividend')}
							minWidth="72"
							cellRender={calculateDollarValue}
						/>
					)}
					{!appContext.isJapanAccount && (
						<Column
							allowReordering={true}
							caption={tt('COMMISSION')}
							sortIndex={getAttribute('sortIndex', 'comm')}
							sortOrder={getAttribute('sortOrder', 'comm') ?? 'desc'}
							dataField="comm"
							alignment="right"
							visible={getAttribute('visible', 'comm') ?? false}
							visibleIndex={getAttribute('visibleIndex', 'comm') ?? 14}
							allowSearch={!emptyState}
							width={getAttribute('width', 'comm')}
							minWidth="72"
							cellRender={calculateDollarValue}
						/>
					)}
					<Column
						allowReordering={true}
						caption={tt('POSITION_COMMENT')}
						sortIndex={getAttribute('sortIndex', 'comment')}
						sortOrder={getAttribute('sortOrder', 'comment') ?? 'desc'}
						dataField="comment"
						alignment="right"
						visible={getAttribute('visible', 'comment') ?? false}
						visibleIndex={getAttribute('visibleIndex', 'comment') ?? 15}
						allowSearch={!emptyState}
						width={getAttribute('width', 'comment')}
						minWidth="72"
						allowSorting={false}
						cellRender={calculateComment}
					/>
					<Column
						allowReordering={true}
						caption={tt('POSITION_ID')}
						sortIndex={getAttribute('sortIndex', 'posId')}
						sortOrder={getAttribute('sortOrder', 'posId') ?? 'desc'}
						dataField="posId"
						alignment="right"
						visible={getAttribute('visible', 'posId') ?? false}
						visibleIndex={getAttribute('visibleIndex', 'posId') ?? 16}
						allowSearch={!emptyState}
						width={getAttribute('width', 'posId')}
						minWidth="72"
					/>
					<Column
						fixedPosition={isArabic ? 'left' : 'right'}
						allowReordering={false}
						allowHiding={false}
						allowSorting={false}
						headerCellRender={showCogIcon}
						cellRender={showEditIcon}
						allowFiltering={false}
						visible={!emptyState}
						visibleIndex={17}
						allowSearch={!emptyState}
						allowResizing={false}
						cssClass={styles.closeAndMoreOptionsCell}
						width={40}
						fixed={true}
					/>
					<Column
						fixedPosition={isArabic ? 'left' : 'right'}
						allowReordering={false}
						allowHiding={false}
						allowSorting={false}
						headerCellRender={showCloseIcon}
						cellRender={showClosePositionIcon}
						allowFiltering={false}
						visible={!emptyState}
						visibleIndex={18}
						allowSearch={!emptyState}
						allowResizing={false}
						width={40}
						cssClass={styles.closeAndMoreOptionsCell}
						fixed={true}
					/>
					<Scrolling showScrollbar={emptyState ? 'never' : 'always'} mode="standard" />
				</TreeList>
			</div>
		);
	},
	(prevProps, nextProps) => {
		return prevProps.searchTerm === nextProps.searchTerm;
	}
);

export default PositionGrid;
